import Banner from "@Models/banner.model";
import Image from "next/image";
import Link from "@Components/Link";
import React from "react";
import { isMobile } from "react-device-detect";
import Slider from "react-slick";
import { IAuthState } from "state/reducer/auth";
import { currency } from "utils";
import LinkOrActionAuth from "@Components/LinkOrActionAuth";

interface IBanner {
   imgPc: string;
   imgMb: string;
   title: string;
   url?: string;
}
interface IProps {
   auth: IAuthState;
   data: Banner[];
   goToEarnCoin: () => void;
}
interface IState {
   banners: IBanner[];
}

export default class Banners extends React.Component<IProps, IState> {
   constructor(props: IProps) {
      super(props);
      this.state = {
         banners: [
            {
               imgPc: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/banner/homepage-slide-web-1.webp",
               imgMb: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/banner/homepage-slide-mobile-1.webp",
               title: "Shop",
               url: "/shop",
            },
            {
               imgPc: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/banner/home-web-3.webp",
               imgMb: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/banner/home-mobile-3.webp",
               title: "Feed",
               url: "/feed",
               // url: "/quy-trinh-ket-noi?internal=home-banner-2-v2",
            },
            {
               imgPc: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/banner/home-web-4.webp",
               imgMb: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/banner/home-mobile-4.webp",
               title: "Space T",
               url: "/bao-gia?internal=home-banner-3-v2",
            },
            {
               imgPc: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/banner/home-web-5.webp",
               imgMb: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/banner/home-mobile-5.webp",
               title: "Công cụ Dự toán Chi phí Thi công Nội thất - Space T",
               url: "/thiet-ke-2d-3d",
            },
            {
               imgPc: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/banner/home-web-6.webp",
               imgMb: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/banner/home-mobile-6.webp",
               title: "Miễn phí Thiết kế 2D khi Hoàn thiện Nội thất cùng Space T",
               url: `/chuong-trinh-gioi-thieu-ban`,
            },
            {
               imgPc: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/banner/home-web-7.webp",
               imgMb: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/banner/home-mobile-7.webp",
               title: "Chương trình Giới Thiệu Bạn - Nhận Thưởng Hấp Dẫn - Space T",
               url: "/blog",
            },
         ],
      };
   }
   setPreviousURL = (banner: any) => {
      if (typeof window !== "undefined") {
         if (banner && banner?.url?.includes("/cau-hoi-khao-sat")) {
            sessionStorage.setItem("previous_url", "");
            sessionStorage.setItem("previous_url", window?.location?.href);
         }
      }
   };
   render() {
      return (
         <div className="block_banner">
            <div className="st-container">
               <div className="page-banner">
                  <div className="slider">
                     <Slider
                        arrows={isMobile ? false : true}
                        dots={false}
                        speed={500}
                        infinite={true}
                        slidesToShow={1}
                        slidesToScroll={1}
                        autoplay={true}
                        autoplaySpeed={6000}
                        prevArrow={
                           <div>
                              <span className="slick-arrow-custom">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                       fill-rule="evenodd"
                                       clip-rule="evenodd"
                                       d="M10.9393 17.0607C10.3536 16.4749 10.3536 15.5251 10.9393 14.9393L18.9393 6.93934C19.5251 6.35355 20.4749 6.35356 21.0607 6.93934C21.6464 7.52513 21.6464 8.47488 21.0607 9.06066L14.1213 16L21.0607 22.9393C21.6464 23.5251 21.6464 24.4749 21.0607 25.0607C20.4749 25.6464 19.5251 25.6464 18.9393 25.0607L10.9393 17.0607Z"
                                       fill="white"
                                    />
                                 </svg>
                              </span>
                           </div>
                        }
                        nextArrow={
                           <div>
                              <span className="slick-arrow-custom">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path
                                       fill-rule="evenodd"
                                       clip-rule="evenodd"
                                       d="M21.0607 14.9393C21.6464 15.5251 21.6464 16.4749 21.0607 17.0607L13.0607 25.0607C12.4749 25.6464 11.5251 25.6464 10.9393 25.0607C10.3536 24.4749 10.3536 23.5251 10.9393 22.9393L17.8787 16L10.9393 9.06066C10.3536 8.47487 10.3536 7.52513 10.9393 6.93934C11.5251 6.35355 12.4749 6.35355 13.0607 6.93934L21.0607 14.9393Z"
                                       fill="white"
                                    />
                                 </svg>
                              </span>
                           </div>
                        }
                     >
                        {this.props.data.map((banner, i) => {
                           return (
                              <div className="slider-item" key={i}>
                                 {banner.reaction && (
                                    <Link title="Banner" href={banner.reaction} className="link-banner" onClick={() => this.setPreviousURL(banner)}></Link>
                                 )}
                                 <picture>
                                    <source media="(min-width: 768px)" srcSet={banner.mediaUrl ?? ""} />
                                    <source media="(max-width: 767px)" srcSet={banner.mediaUrl ?? ""} />
                                    <Image width="500" height="200" title={banner?.meta?.title} alt={banner?.meta?.altText} src={banner.mediaUrl ?? ""} />
                                 </picture>
                              </div>
                           );
                        })}
                     </Slider>
                  </div>
                  <Link className="right-banner" href="/noithatgiasi">
                     <img src={"https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/banner/home-right-banner.webp"} alt="" />
                  </Link>
               </div>
               <div className="coin-attention">
                  <img className="coin-bg" src="/assets/img/home/coin-bg.png" alt="" />
                  <div className="content-absolute">
                     <div className="left">
                        <div className="label">{!this.props.auth?.user ? "Đăng kí nhận ngay" : "Số xu hiện có:"}</div>
                        <div className="d-flex align-items-center">
                           <img className="coin-icon" src="/assets/img/home/coin.png" alt="" />
                           <span className="coin-value">{!this.props.auth?.user ? "100.000" : currency(this.props.auth?.points?.useablePoint)}</span>
                        </div>
                     </div>
                     <div className="right">
                        <div className="label">{!this.props.auth?.user ? "Tích luỹ nhiều xu hơn nữa:" : "Bạn có 3 nhiệm vụ kiếm xu"}</div>
                        <LinkOrActionAuth
                           isLogin={!!this.props.auth?.user}
                           className="d-flex align-items-center"
                           onClick={() => {
                              this.props.goToEarnCoin();
                           }}
                        >
                           <span className="coin-earn-link">Kiếm xu ngay</span>
                           <svg className="arrow-right" xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                              <path d="M6.5 4L10.5 8L6.5 12" stroke="#FF6347" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                           </svg>
                        </LinkOrActionAuth>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      );
   }
}
